import React from "react";
import { Link } from "@reach/router";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import Title from "@components/Title";

export default () => (
  <>
    <Helmet title="Page not found" />
    <StyledContainer className="text-center">
      <Title as="h1" className="pt-2 pb-2">
        Page Not Found
      </Title>
      <p>
        We could not find the page you were looking for, please{" "}
        <Link to="/">click here</Link> to return.
      </p>
    </StyledContainer>
  </>
);

const StyledContainer = styled.div`
  min-height: 500px;
`;
