import React from "react";
import styled, { css } from "styled-components";

interface Props {
  color?: string;
  className?: string;
  children: React.ReactNode;
  as: keyof JSX.IntrinsicElements;
}

/**
 * Converts `keyof JSX.IntrinsicElements` into a class
 * name for the purpose of styling (e.g .h2, .h3 etc)
 */
export default ({ as, children, color, className = "" }: Props) => (
  <StyledContainer
    as={as}
    color={color}
    className={`${as.toString()} ${className}`}
  >
    {children}
  </StyledContainer>
);

const StyledContainer = styled.h1<{ color?: string }>`
  &&& {
    font-size: 2rem;
    line-height: 1.4;

    ${({ color }) =>
      color &&
      css`
        color: ${color};
      `}

    &.h2 {
      font-weight: 400;
      font-size: 1.3rem;
    }

    &.h3 {
      font-size: 1.2rem;
    }

    &.h4 {
      font-size: 1.1rem;
    }

    @media (max-width: ${({ theme }) => theme.breakpointMedium}) {
      font-size: 1.8rem;

      &.h2 {
        font-size: 1.4rem;
      }

      &.h3,
      &.h4 {
        font-size: 1.1rem;
      }
    }

    @media (max-width: ${({ theme }) => theme.breakpointSmall}) {
      font-size: 1.4rem;

      &.h2 {
        font-size: 1.1rem;
      }

      &.h3,
      &.h4 {
        font-size: 1rem;
      }
    }
  }
`;
